import React from 'react'
import { Footer, DivFootLinks, DivLogoFoot, DivLinks, DivContatoFoot, SolutionLink,
WowLink, CriarContaLink, TituloContato, SociaisContato, EmailContato,
TelefoneContato, EnderecoContato, DivRightsFoot, WhatsContato } from './styles'

import WowLogo from '../../images/wowSolutionLogo.svg'
import { Link } from 'gatsby'

import FaceIcon from '../../images/faceIcon.svg'
import LinkedinIcon from '../../images/linkedinIcon.svg'
import InstaIcon from '../../images/instaIcon.svg'
import YoutubeIcon from '../../images/youtubeIcon.svg'
import TwitterIcon from '../../images/twitterIcon.svg'
import EmailIcon from '../../images/emailIcon.svg'
import TelIcon from '../../images/telefoneIcon.svg'
import PinIcon from '../../images/pinIcon.svg'
import WhatsIcon from '../../images/WhatsIcon.svg'
import PCIcomplaint from '../../images/PCI.svg'


export default () => {

   return(

      <Footer>
         <DivFootLinks>

            <DivLogoFoot>
               <img src={WowLogo} alt='Logo' />
               <img src={PCIcomplaint} alt='PCI' />
            </DivLogoFoot>

            <DivLinks>
               <SolutionLink>
                  <nav>
                     <ul>
                        <li><a href='https://www.wowsolution.com/' rel="noopener noreferrer" target='_blank'><b>WOW SOLUTION</b></a></li>
                        <li><a href='/home/#conta-digital' rel="noopener noreferrer" >SUA CONTA DIGITAL</a></li>
                        <li><a href='/home/#servicos' rel="noopener noreferrer" >SERVIÇOS</a></li>
                        <li><a href='/home/#ser-wow' rel="noopener noreferrer" >QUEM SOMOS!</a></li>
                        <li><a href='/home/#app-wow' rel="noopener noreferrer">APP WOW</a></li>
                     </ul>
                  </nav>
               </SolutionLink>
               <WowLink>
                  <nav>
                     <ul>
                        <li><Link to='/manager'>WOW MANAGER</Link></li>
                        <li><Link to='/pay'>WOW PAY</Link></li>
                        <li><Link to='/card'>WOW CARD</Link></li>
                        {/* <li><Link to='/cred'>WOW CRED</Link></li>
                        <li><Link to='/trust'>WOW TRUST</Link></li>
                        <li><Link to='/club'>WOW CLUB</Link></li> */}
                     </ul>
                  </nav>
               </WowLink>
               <CriarContaLink>
                  <nav>
                     <ul>
                        <li><a className='cria-footer' href='https://www.wowmanager.com.br/signup' rel="noopener noreferrer" target='_blank' >CRIAR CONTA</a></li>
                        {/* <li><a href='#' rel="noopener noreferrer" target='_blank'><b>PORTAL CLIENTE</b></a></li> */}
                        {/* <li><a href='#' rel="noopener noreferrer" target='_blank'>BLOG</a></li> */}
                        <li><a href='/contato' rel="noopener noreferrer" target='_blank'>CONTATO</a></li>
                        {/* <li><a href='#' rel="noopener noreferrer" target='_blank'>TRABALHE CONOSCO</a></li> */}
                     </ul>
                  </nav>
               </CriarContaLink>
            </DivLinks>

            <DivContatoFoot>
               <TituloContato>
                  <div>
                     <span>Siga nós!</span>
                  </div>
               </TituloContato>
               <SociaisContato>
                  <nav>
                     <ul>
                        <li><a href='https://www.facebook.com/wowsurpreendente/' rel="noopener noreferrer" target='_blank'><img src={FaceIcon} alt='Facebook' /></a></li>
                        <li><a href='https://www.linkedin.com/company/wow-solution/' rel="noopener noreferrer" target='_blank'><img src={LinkedinIcon} alt='Linkedin' /></a></li>
                        <li><a href='https://www.instagram.com/_wowsolution/' rel="noopener noreferrer" target='_blank'><img src={InstaIcon} alt='Instagram' /></a></li>
                        <li><a href='https://www.youtube.com/channel/UC199iBno9k7j7A-lbGNGCGQ/featured' rel="noopener noreferrer" target='_blank'><img src={YoutubeIcon} alt='YouTube' /></a></li>
                        <li><a href='https://twitter.com/SejaWow' rel="noopener noreferrer" target='_blank'><img src={TwitterIcon} alt='Twitter' /></a></li>
                     </ul>
                  </nav>
               </SociaisContato>
               <EmailContato>
                  <img src={EmailIcon} alt='Email'/>
                  <span>atendimentowow@wowsolution.com</span>
               </EmailContato>
               <TelefoneContato>
                  <img src={TelIcon} alt='Telefone'/>
                  <span>+55 11 4550 7420</span>
               </TelefoneContato>
               <WhatsContato>
                  <img src={WhatsIcon} alt="WhatsIcon"/>
                  <span>+55 11 99547 1383</span>
               </WhatsContato>
               <EnderecoContato>
                  <img src={PinIcon} alt='Pin'/>
                  <div>
                     <span>Wow Solution</span>
                     <span>Rua Bela Cintra, 1149</span>
                     <span>Consolação - São Paulo</span>
                  </div>
               </EnderecoContato>
            </DivContatoFoot>

         </DivFootLinks>

         <DivRightsFoot>
            <div className='text-left'>
               <span>Mude para melhor e surpreenda!</span>
            </div>
            <div className='text-right'>
               <span>Todos os direitos reservados a WowSolution 2020</span>
            </div>
         </DivRightsFoot>

      </Footer>

   )

}