import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { bool } from 'prop-types'
import { Header, ContatosSection, DivLinks, ResponsiveMenu, Li } from './styles'
import Logo from '../../images/wowSolutionLogo.svg'
import EmailIcon from '../../images/emailIcon.svg'
import PhoneIcon from '../../images/telefoneIcon.svg'

export default ({ open, currentPage }) => {

   const data = useStaticQuery(graphql`
      {
         site {
            siteMetadata {
               contato
               telefone
               menuLinks{
                  name
                  link
               }
            }
         }
      }
   `)

   return(
      <Header>

         <div className='navbar'>          
            <DivLinks>
               <Link className="logo-header" to="/"><img src={Logo} alt="logo"></img></Link>
               <nav>
                  <ul>
                     {data.site.siteMetadata.menuLinks.map(paginas => (
                        <Li key={paginas.name} selected={currentPage === paginas.name? true : false}>
                           <Link to={paginas.link}>
                              {paginas.name}
                           </Link>
                        </Li>
                     ))}
                     <Li className='entrar-header'>
                        <a href='https://www.wowmanager.com.br/' rel="noopener noreferrer" target='_blank'>Entrar</a>
                     </Li>
                  </ul>
                  <a className='cria-conta-header' href='https://www.wowmanager.com.br/signup' rel="noopener noreferrer" target='_blank'>Criar Conta</a>
               </nav>
            </DivLinks>
            <ContatosSection>
               <div>
                  <img src={EmailIcon} alt="email"></img>
                  <div className='conta-text'>
                     <span>{data.site.siteMetadata.contato}</span>
                  </div>
               </div>
               <div>
                  <img src={PhoneIcon} alt="phone"></img>
                  <div className='conta-text'>
                     <span>{data.site.siteMetadata.telefone}</span>
                  </div>
               </div>
            </ContatosSection>
         </div>

         <ResponsiveMenu open={open} className="responsive-menu">      

            <DivLinks>
               <Link className="logo-header" to="/"><img src={Logo} alt="logo"></img></Link>
               <nav>
                  <ul>
                     {data.site.siteMetadata.menuLinks.map(paginas => (
                        <Li key={paginas.name}>
                           <Link to={paginas.link}>
                              {paginas.name}
                           </Link>
                        </Li>
                     ))}
                     <Li className='entrar-header'>
                        <a href='https://www.wowmanager.com.br/' rel="noopener noreferrer" target='_blank'>Entrar</a>
                     </Li>
                  </ul>
                  <a className='cria-conta-header' href='https://www.wowmanager.com.br/signup' rel="noopener noreferrer" target='_blank'>Criar Conta</a>
               </nav>
            </DivLinks>
            
         </ResponsiveMenu>

      </Header>
   )

}

Header.propTypes = {
   open: bool.isRequired,

};