// HEADER STYLE
import styled from 'styled-components'

export const Header = styled.header`

   & > .navbar{

      @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
         -webkit-backdrop-filter: blur(10px);
         backdrop-filter: blur(10px);
      }

      height: inherit;
      position: fixed;
      z-index: 999;
      top: 0px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 130px;
      background: rgba(30, 55, 96, 0.5);
      justify-content: center;
   }

   @media (min-width: 320px) and (max-width: 840px){
      & > .navbar{
         display:none;
      }
   }

   @media (min-width: 840px) and (max-width: 1024px){
      & > .navbar{
         position: fixed;
      }
   }

`

export const ResponsiveMenu = styled.div`

  background-color: #1E3760;

   display: flex;
   flex-direction: column;
   height: 100%;
   text-align: left;
   padding: 2rem;
   padding-top:150px;
   position: fixed;
   z-index: 5;
   top: 0;
   left: 0;
   transition: transform 0.3s ease-in-out;
   transform: translateX(-100%);
   transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};

   @media (min-width: 841px){
      display:none;
   }

`

export const DivLinks = styled.div`

   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 1280px;
   align-items: flex-end;
   padding-bottom: 15px;
   
   & > .logo-header {
      display: block;

      & > img {
         height: 60px;
      }
   }

   & > nav {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      & > ul {
         padding-left: 0;
         display:flex;
         font-size: 14px;
         & > .entrar-header > a{
            color: #22EEE1;
         }
      }

      & > .cria-conta-header{
            
         background-color: #22EEE1;
         border: 1px solid #25406D;
         border-radius: 22px;
         padding: 5px 13px;
         color: #25406D;
         text-decoration: none;
         margin-left: 35px;
      }
   } 

   @media (min-width: 320px) and (max-width: 840px){
      
      flex-direction: column;
      width: auto;
      align-items: flex-start;

      & > .logo-header{
         margin-bottom: 25px;
      }
      
      & > nav{
         flex-direction: column;
         align-items: flex-start;

         & > ul{
            flex-direction: column;
         }

         & > .cria-conta-header{
            margin-left: 0;
         }
      }

   }

`

export const Li = styled.li`

   margin-left: 30px;
   float: left;
   position: relative;
   list-style: none;
   cursor: pointer;

   &:hover{
      &:after{
         width: 100%;
         
         @media (min-width: 320px) and (max-width: 840px){
            width: 0%;
         }
      
      }
      
   }

   &:after{
      transition: width 0.3s;
      position: absolute;
      height: 2px;
      background-color: #22EEE1;
      content: '';
      width: 0;
      left: 0;
      top:45px;
   }

   &.entrar-header{
      a {
         color:#22EEE1;
      }
   }
/* 
   &:first-child{
      margin-left: 0;
   } */
   a{
      color: #fff;
      display: block;
      text-decoration: none;
   }

   @media (max-width: 1024px){
      margin-left: 12px;
      & > a{
         font-size: 12px;
      }
   }

   @media (min-width: 320px) and (max-width: 840px){
      display: block;
      margin-bottom: 20px;
      margin-left: 0;
      & > a{
         font-size: 20px;
      }
   }

`

export const ContatosSection = styled.div`

   height: 53px;
   background-color: #1E3760;
   max-width: 45%;
   width: 100%;
   right: 0;
   /* position: fixed; */
   position: absolute;
   display: flex;
   font-size: 14px;
   justify-content: space-evenly;
   align-items: center;

   & > div {
      display: flex;
      
      & > img {
         height: 20px;
         width: 20px;
         padding-top: 2px;
      }

      & > .conta-text{
         align-items: center;
         color: #F2F3F8;
         line-height: 16px;
         padding: 5px;
      }
   }

   & > div:last-child > img {
      height: 31px;
      width: 31px;
   }

   @media (max-width: 1024px){
      max-width: 50%;
   }

   @media (min-width: 320px) and (max-width: 840px){
      display: none;
   }

`


export const DivPages = styled.div`

   width: 100%;
   display: flex;
   align-items: flex-end;
   flex-flow: row nowrap;
   justify-content: center;
   
   & > nav > ul > .entrar-btn > a{
      color: #22EEE1;
   }
   
   & > nav > ul > .criar-conta > a{
      padding: 5px 15px;
      background: #22EEE1;
      border: 1px solid #25406D;
      border-radius: 22px;
      color: #25406D;
   }

`