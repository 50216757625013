import React from 'react'
import { bool, func } from 'prop-types';
import { ResponsiveHeader } from './styles'

export default ({ open, setOpen, ...props }) => {

    const isExpanded = open ? true : false;

    return(
                
        <ResponsiveHeader aria-label="Toggle menu" aria-expanded={isExpanded} open={open} onClick={() => setOpen(!open)} {...props}>
            <span></span>
            <span></span>
            <span></span>
        </ResponsiveHeader >
    )

}

ResponsiveHeader.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};