import styled from 'styled-components'

export const ResponsiveHeader = styled.div`

	position: fixed;
	top: 5%;
	left: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	color: #10ac84;

	&:focus {
		outline: none;
	}

	span {
    width: 2rem;
    margin-bottom: 1px;
    height: 0.25rem;
    background: ${({ open }) => open ? '#48dbfb' : '#25406D' };
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media (min-width: 1024px) and (max-width: 2560px){
    display: none;
  }


`