import styled from 'styled-components'

export const Footer = styled.footer`

   background: #1E3760;
   display: flex;
   justify-content: center;

   & > div{
      display: flex;
      
   }

`

export const DivFootLinks = styled.div`

   display: flex;
   width: 100%;
   justify-content: space-evenly;
   margin-top: 80px;
   margin-bottom: 80px;

   @media (min-width: 320px) and (max-width: 835px){
      flex-direction: column;
      align-items: center;
   }

`

export const DivLogoFoot = styled.div`

   display: flex;
   flex-direction: column;
   justify-content: space-between;

   & > img{
      height: 93px;
   }

   & >img:last-child{
      height: 50px;
   }

   @media (max-width: 1024px){
      & > img{
         height: 73px;
         margin-bottom: 20px;
      }
   }


`

export const DivLinks = styled.div`

   display: flex;
   flex-direction: row;
   color:white;
   margin-top: 40px;

   & > div{

      & > nav > ul{
         & > li{
            margin-top:15px;
         }

         @media(min-width: 320px) and (max-width: 414px){
            padding-left: 10px;
         }

      }
   }

   

`

export const DivContatoFoot = styled.div`
   
   display: flex; 
   flex-direction: column; 
   color: white;
   margin-top:30px;

   & > div{
      margin-bottom: 25px;

      & > span{
         margin-left: 10px;
      }
   }  

`

export const SolutionLink = styled.div`

   font-size: 14px;
   line-height: 28px;
   display: flex;
   flex-direction: column;

   & > nav > ul { 
      list-style: none;

      & > li > a{
         margin-top: 15px;
         color: white;
         text-decoration: none;

      }

   }
   
   
   

`

export const WowLink = styled.div`

   font-size: 14px;
   line-height: 28px;
   display: flex;
   flex-direction: column;

   & > nav > ul {
      list-style: none;


      & > li > a{
         margin-top: 15px;
         font-weight: bold;
         color: white;
         text-decoration: none;
      }

   }


   

`

export const CriarContaLink = styled.div`

   font-size: 14px;
   line-height: 28px;
   display: flex;
   flex-direction: column;

   & > nav > ul {

      list-style: none;

      & > .cria-footer{
         color: #22EEE1;
      }

      & > li > a{
         margin-top: 15px;
         color: white;
         text-decoration: none;
      }
   }

`

export const TituloContato = styled.div`

   & > div{
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #22EEE1;
   }

`

export const SociaisContato = styled.div`

   display: flex;
   flex-direction: row;

   & > nav > ul{
      
      list-style: none;
      display: inline-flex;
      padding-left: 0;

      & > li > a{
         
         & > img{
            margin-right: 10px;
            max-height: 45px;
            
         }
      }
   }


`

export const EmailContato = styled.div`

   font-size: 14px;
   line-height: 16px;
   display: flex;
   align-items: center;

   & > img{
      max-height: 35px;
   }

`

export const TelefoneContato = styled.div`

   display: flex;
   align-items: center;
   
   & > img{
      max-height: 35px;
   }

`

export const EnderecoContato = styled.div`

   display: flex;

   & > div{
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      & > span{
         font-size: 16px;
      }

   }

   & > img{
      max-height: 35px;
   }
`

export const WhatsContato = styled.div`

   line-height: 16px;
   display: flex;
   align-items: center;

   & > img{
      max-height: 35px;
   }

`

export const DivRightsFoot = styled.div`

   background: #1A2944;
   position: absolute;
   align-self: flex-end;
   width: 100%;
   justify-content: space-between;

   & > .text-left{
      font-size: 18px;
      line-height: 20px;
      color: #22EEE1;
      margin-left: 10px;
   }

   & > .text-right{
      color: #0E4F8A;
      font-size: 14px;
      line-height: 20px;
      margin-right: 10px;

   }


`
